<template>
	<div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="my_data==null">

		<SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('booth.name')}}
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12 mrb-25">
                <Skeleton></Skeleton>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="service-detail-text">
                  <h6 class="text mrb-10"> {{$t('booth.note')}}</h6>
                  <article class="art_text">
                    <Skeleton></Skeleton>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </SkeletonTheme>
       
    </div>

        <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('booth.name')}}
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12 mrb-25">
                 <a :href="'/'+$i18n.locale+'/thue-quang-cao'" title="thue quang cao">
                    <img class="lazy" src="@/assets/default/images/qc22.png">
                  </a>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="service-detail-text">
                  <h6 class="text mrb-10"> {{$t('booth.note')}}</h6>
                  <article class="art_text">
                    <div class="cho_thue">
                      <img class="lazy" src="@/assets/default/images/so-do-ben-xe-123.jpg">


                      <div class="vitri khu-h" v-for="(v,k) in my_data" :key="k" 
                      	:class="'my-zone-'+v.area"
                      	>
                        <i class="fas fa-map-marker-alt"></i>
                        <a :href="$router.resolve({name:'booth.detail',params:{locale:$i18n.locale,slug:v.area}}).href" title="Khu h">
                          {{$t('booth.zone')}} {{v.area}}
                        </a>
                      </div>
                     

                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        

         <section class="container mrt-30">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12 mrb-25">
                 <a :href="'/'+$i18n.locale+'/thue-quang-cao'" title="thue quang cao">
                  <img class="lazy" src="@/assets/default/images/qc33.png">
                </a>
              </div>
            </div>
          </section>
          <section class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="service-detail-text">
                  <h6 class="text mrb-10">Vị trí đánh dấu là vị trí quầy vé đang cần cho thuê</h6>
                  <article class="art_text">
                    <div class="cho_thue">
                      <img class="lazy" src="@/assets/default/images/so-do-ben-xe-123.jpg">
                     

                       <div class="vitri khu-h" v-for="(v,k) in my_data_a" :key="k" 
                          :class="'my-zone-'+v.area"
                          >
                          <i class="fas fa-map-marker-alt"></i>
                          <a :href="$router.resolve({name:'booth.detail',params:{locale:$i18n.locale,slug:v.area}}).href+'?t=1'" title="Khu h">
                            {{$t('booth.zone')}} {{v.area}}
                          </a>
                        </div>
                       
                       
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>


        </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      my_data_a:null,

    

      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme
    
  },
  created(){
            axios.get(window.my_api+"api/booth/get-booths?language="+this.$i18n.locale,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                       this.my_data=res.data.data.filter( n=> n.type==0 );
                       this.my_data_a=res.data.data.filter( n=> n.type==1 );
                                            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });

          
      
  },
  methods:{
  	
  }
}
</script>
<style>

	

	.my-zone-H{
		top: 38%;left: 8%;
	}
	.my-zone-K{
		top: 48%;left: 14%;
	}
	.my-zone-A{
		top: 31%;left: 22%;
	}
	.my-zone-B{
		top: 31%;left: 38%;
	}
	.my-zone-C{
		top: 31%;left: 56%
	}
	.my-zone-D{
	top: 31%;left: 73%;
	}
	.my-zone-K{
		top: 49%;left: 77%;
	}
	.my-zone-E{
		top: 42%;left: 88%;
	}
	
</style>

												